import React from "react";
import "./Features.css";
import ImageCard from "./ImageCard";
import image1 from "../images/image1.png";
import image2 from "../images/image2.png";
import image3 from "../images/image3.png";
import image4 from "../images/image4.png";
import image5 from "../images/image5.png";

function Features() {
  return (
    <div className="features">
      <ImageCard
        image={image1}
        title="Échange 24h/24h 7j/7j avec un expert de la performance"
        description="Pose tes questions quand tu le souhaites, notre expert te guide !"
      />
      <ImageCard
        image={image2}
        title="Accède en illimité à nos formations et nos contenus exclusifs"
        description="Apprend à ton rythme et selon tes besoins ! (Nutrition, préparation mentale, préparation physique, etc.)"
      />
      <ImageCard
        image={image3}
        title="Découvre les conseils de nos ambassadeurs !"
        description="Discute avec des joueurs professionnels et obtiens de précieux conseils !"
      />
      <ImageCard
        image={image4}
        title="Gagne de l'XP en participant aux évènements..."
        description="Participe à la vie de la communauté, réponds à des quizz et entraine-toi pour gagner des niveaux !"
      />
      <ImageCard
        image={image5}
        title="...et tente de gagner des cadeaux toutes les semaines !"
        description="Toutes les semaines, participe à nos jeux concours pour gagner des cadeaux !"
      />
    </div>
  );
}

export default Features;
