import { createClient } from "@supabase/supabase-js";

const supabase = createClient(
  "https://qwnqlwzlglzgcrpdhjef.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF3bnFsd3psZ2x6Z2NycGRoamVmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE3NjEyMzEsImV4cCI6MjAxNzMzNzIzMX0.CSy3st6EFMnjxKYECfv9h-62OeKW3lXlxxIM-rW0qaE"
);

function Success() {
  async function signedOut() {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.log(error);
    }
  }
  return (
    <div className="App">
      <header className="App-header">
        <h1>Success !</h1>
        <button onClick={signedOut}>Logout</button>
      </header>
    </div>
  );
}

export default Success;
