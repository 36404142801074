import React from "react";
import "./ImageCard.css";

function ImageCard({ image, title, description }) {
  return (
    <div className="image-card">
      <img src={image} alt={title} className="image-card-img" />
      <h3 className="image-card-title">{title}</h3>
      <p className="image-card-description">{description}</p>
      <div className="separation" />
    </div>
  );
}

export default ImageCard;
