import React from "react";
import "./Footer.css"; // Assurez-vous de créer ce fichier CSS

function Footer() {
  return (
    <footer>
      <a
        href="https://www.instagram.com/enjeuxfootball/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-instagram"></i>
      </a>
      <a
        href="https://www.tiktok.com/@enjeuxfootball?lang=fr"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-tiktok"></i>
      </a>
    </footer>
  );
}

export default Footer;
