import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA55samtgSw8vRP8-UDJCXfcKXsIEJkAs8",
  authDomain: "enjeux-app.firebaseapp.com",
  databaseURL: "https://enjeux-app.firebaseio.com",
  projectId: "enjeux-app",
  storageBucket: "enjeux-app.appspot.com",
  messagingSenderId: "579489040938",
  appId: "1:579489040938:web:538e39e5aa0bfbc9ccd153",
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Obtenir les instances pour Firestore et Auth
export const db = getFirestore(app);
export const auth = getAuth(app);
