import { createClient } from "@supabase/supabase-js";
import { useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import moment from "moment";
import "./loginPage.css";
import { createUserWithEmailAndPassword } from "firebase/auth";
import Header from "../components/Header";
import Features from "../components/Features";
import Footer from "../components/Footer";

const supabase = createClient(
  "https://qwnqlwzlglzgcrpdhjef.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InF3bnFsd3psZ2x6Z2NycGRoamVmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDE3NjEyMzEsImV4cCI6MjAxNzMzNzIzMX0.CSy3st6EFMnjxKYECfv9h-62OeKW3lXlxxIM-rW0qaE"
);

const redirectToExternalURL = (url) => {
  window.location.href = url;
};

function generatePassword() {
  const lowerCaseChars = "abcdefghijklmnopqrstuvwxyz";
  const upperCaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numericChars = "0123456789";
  const specialChars = "!@#$%&*()_+{}:<>?[]";
  const allChars =
    lowerCaseChars + upperCaseChars + numericChars + specialChars;
  const passwordLength = 8;
  let password = "";

  // S'assurer que le mot de passe contient au moins un caractère de chaque type
  password += lowerCaseChars.charAt(
    Math.floor(Math.random() * lowerCaseChars.length)
  );
  password += upperCaseChars.charAt(
    Math.floor(Math.random() * upperCaseChars.length)
  );
  password += numericChars.charAt(
    Math.floor(Math.random() * numericChars.length)
  );
  password += specialChars.charAt(
    Math.floor(Math.random() * specialChars.length)
  );

  // Compléter le reste du mot de passe avec des caractères aléatoires
  for (let i = password.length; i < passwordLength; i++) {
    password += allChars.charAt(Math.floor(Math.random() * allChars.length));
  }

  // Mélanger les caractères du mot de passe
  password = password
    .split("")
    .sort(() => 0.5 - Math.random())
    .join("");

  console.log(password);

  return password;
}

const checkUserExistsByEmail = async (email) => {
  console.log(email);
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("email", "==", email));
  const querySnapshot = await getDocs(q);

  return !querySnapshot.empty;
};

function Login() {
  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then(async (discordUser) => {
        if (discordUser.data.user !== null) {
          const userExists = await checkUserExistsByEmail(
            discordUser.data.user.email
          );
          console.log(userExists);
          if (!userExists) {
            const userCredential = await createUserWithEmailAndPassword(
              auth,
              discordUser.data.user.email,
              generatePassword()
            );

            const firebaseUser = userCredential.user;
            await setDoc(doc(db, "users", firebaseUser.uid), {
              uid: firebaseUser.uid,
              email: discordUser.data.user.email,
              role: "premium",
              free_trial: true,
              end_free_trial: moment().add(7, "days").toDate(),
              discord_metadata: discordUser.data.user.user_metadata,
            });
          }

          redirectToExternalURL("https://discord.gg/zctaeGBp6Q");
        }
      });
    }

    getUserData();
  }, []);

  async function signInWithDiscord() {
    await supabase.auth.signInWithOAuth({
      provider: "discord",
    });
  }

  return (
    <div className="App">
      <Header signInWithDiscord={signInWithDiscord} />
      <Features />
      <div className="cta">
        <button className="discord-button" onClick={signInWithDiscord}>
          Rejoins le discord gratuitement !
        </button>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
