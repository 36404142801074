import React from "react";
import "./Header.css";
import header from "../images/header.png";

function Header({ signInWithDiscord }) {
  return (
    <header>
      <h1>Ta Plateforme IA pour Exceller Sur et Hors du Terrain.</h1>
      <h3>Joue, Apprend, Gagne</h3>
      <img src={header} alt="header" />
      <button className="discord-button" onClick={signInWithDiscord}>
        Rejoins le discord gratuitement !
      </button>
    </header>
  );
}

export default Header;
